import { Grid } from "@material-ui/core";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import { formatCpfCnpj } from "../../../utils/formatCpfCnpj";
import { formatDate } from "../../../utils/formatDate";
import formatCurrency from "../../../utils/formatCurrency";
import { FaRegFileAlt } from "react-icons/fa";
import { VscRemove } from "react-icons/vsc";
import { RiFilePaper2Line } from "react-icons/ri";
import { IoMdDownload } from "react-icons/io";
import { useStyle } from "./style";
import {
  EditIconButton,
  PaymentIconButton,
} from "../../../components/StyledIconButton";

export const OrderTitleCard = ({
  handleOpenReschedule,
  handleOpenPayment,
  status,
  hasPermission,
  processingReschedule,
  isBoleto,
  isPaymentDisabled,
}) => {
  const isPermitted = hasPermission("can reschedule order");
  const classes = useStyle();
  return (
    <Grid className={classes.titleCard}>
      <p>Informações do Pedido</p>
      {!["cancelado", "recusado", "reagendado"].includes(status) &&
        isPermitted &&
        !processingReschedule && (
          <div>
            {isBoleto && !isPaymentDisabled && (
              <PaymentIconButton onClick={handleOpenPayment} />
            )}
            <EditIconButton onClick={handleOpenReschedule} />
          </div>
        )}
    </Grid>
  );
};

export const OrderStatusCard = ({ colorStatus, orderData }) => {
  const classes = useStyle();
  return (
    <Grid className={classes.orderStatusCard}>
      <p>
        <Brightness1Icon style={{ color: colorStatus(orderData.status) }} />
      </p>
      <p>{orderData.status}</p>
    </Grid>
  );
};

export const OrderBodyCard = ({ orderData }) => {
  const classes = useStyle();
  return (
    <Grid className={classes.orderBodyCard}>
      <Grid className="bodyInfo">
        <span className="infoItem">
          <span className="title">Cliente</span>
          <span className="content">
            {orderData?.client?.trade_name || orderData?.client?.name}
          </span>
        </span>

        <span className="infoItem">
          <span className="title">CPF/CNPJ</span>
          <span className="content">
            {formatCpfCnpj(orderData?.client?.cpf_cnpj)}
          </span>
        </span>

        <span className="infoItem">
          <span className="title">Responsável</span>
          <span className="content">
            {orderData?.client?.responsible?.username}
          </span>
        </span>

        <span className="infoItem">
          <span className="title">Loja</span>
          <span className="content">
            {orderData?.store_name || "Não informado"}
          </span>
        </span>

        {orderData?.kind === "replacement" && (
          <span className="infoItem">
            <span className="title">Reposição</span>
            <span className="content">
              Pedido {`#${orderData?.reference_order}` || "Não informado"}
            </span>
          </span>
        )}

        {orderData?.kind === "reschedule" && (
          <span className="infoItem">
            <span className="title">Reagendamento</span>
            <span className="content">
              Pedido {`#${orderData?.reference_order}` || "Não informado"}
            </span>
          </span>
        )}
      </Grid>
      <Grid className="bodyInfo">
        <span className="infoItem">
          <span className="title">Data do Pedido</span>
          <span className="content">{formatDate(orderData.order_date)}</span>
        </span>

        <span className="infoItem">
          <span className="title">Data agendada de entrega</span>
          <span className="content">
            {formatDate(orderData.scheduled_date)}
          </span>
        </span>

        <span className="infoItem">
          <span className="title">Horário Agendado</span>
          <span className="content">
            {`
                    ${
                      orderData?.scheduled_time_from?.length > 2
                        ? orderData?.scheduled_time_from?.slice(0, 2)
                        : orderData?.scheduled_time_from
                    } hs
                    às ${
                      orderData?.scheduled_time_to?.length > 2
                        ? orderData?.scheduled_time_to?.slice(0, 2)
                        : orderData?.scheduled_time_to
                    } hs
                  `}
          </span>
        </span>

        <span className="infoItem">
          <span className="title">Valor do Pedido</span>
          <span className="content">
            {formatCurrency(orderData?.total_adjusted_value)}
          </span>
        </span>
      </Grid>
    </Grid>
  );
};

export const OrderFooterCard = ({ orderData }) => {
  const classes = useStyle();
  return (
    <Grid className={classes.orderFooterCard}>
      <span className="title">Endereço</span>
      <span className="content">
        {`${
          orderData?.billing_address?.street
            ? `Rua ${orderData?.billing_address?.street}`
            : ""
        } ${orderData?.billing_address?.number || ""} ${
          orderData?.billing_address?.complement
            ? ` - ${orderData?.billing_address?.complement}`
            : ""
        }`}
      </span>
      <span className="content">
        {`${orderData?.billing_address?.neighborhood || ""} - ${
          orderData?.billing_address?.city || ""
        }, ${orderData?.billing_address?.state || ""} `}
      </span>
    </Grid>
  );
};

export const OrderButtonsCard = ({ orderData }) => {
  const classes = useStyle();
  const billingType = orderData?.payment?.[1]?.billingType;

  return (
    <Grid className={classes.orderButtonsCard}>
      {orderData?.urls?.bill_url && (
        <>
          <span>
            <FaRegFileAlt />
            {orderData?.urls?.bill_url ? (
              <a
                href={orderData?.urls?.bill_url}
                target="_blank"
                rel="noreferrer"
              >
                Acessar boleto
              </a>
            ) : (
              <span className="noLink">Acessar boleto</span>
            )}
          </span>
          <span>
            <VscRemove />
          </span>
        </>
      )}

      <span>
        <RiFilePaper2Line />
        {orderData?.urls?.invoice_url ? (
          <a
            href={orderData?.urls?.invoice_url}
            target="_blank"
            rel="noreferrer"
          >
            Acessar Nota Fiscal
          </a>
        ) : (
          <span className="noLink">Acessar Nota Fiscal</span>
        )}
      </span>
    </Grid>
  );
};

export const DeliveryTitleCard = () => {
  const classes = useStyle();
  return (
    <Grid className={classes.titleCard}>
      <p>Informações da Entrega</p>
    </Grid>
  );
};

export const DeliveryStatusCard = ({ orderData, colorStatus }) => {
  const classes = useStyle();
  return (
    <Grid className={classes.orderStatusCard}>
      {orderData?.delivery_status && (
        <>
          <p>
            <Brightness1Icon style={{ color: colorStatus("entregue") }} />
          </p>
          <p>Entregue</p>
        </>
      )}
    </Grid>
  );
};

export const DeliveryBodyCard = ({ deliveryInfo, orderData }) => {
  const classes = useStyle();
  return (
    <Grid className={classes.orderBodyCard}>
      <Grid className="bodyInfo">
        <span className="infoItem">
          <span className="title">Nome do Recebedor</span>
          <span className="content">
            {deliveryInfo?.name || "Não informado"}
          </span>
        </span>

        <span className="infoItem">
          <span className="title">RG/CPF</span>
          <span className="content">
            {deliveryInfo?.document || "Não informado"}
          </span>
        </span>

        <span className="infoItem">
          <span className="title">Nº da Rota</span>
          <span className="content">
            {orderData?.urls?.route ?? "Não informado"}
          </span>
        </span>
      </Grid>
      <Grid className="bodyInfo">
        <span className="infoItem">
          <span className="title">Data da Entrega</span>
          <span className="content">
            {orderData?.delivered_date
              ? formatDate(orderData?.delivered_date)
              : "Não informado"}
          </span>
        </span>

        <span className="infoItem">
          <span className="title">Hora da entrega</span>
          <span className="content">
            {orderData?.arrival_time
              ? `${orderData.arrival_time.slice(11, 16)} hs`
              : "Não informado"}
          </span>
        </span>
      </Grid>
    </Grid>
  );
};

export const DeliveryFooterCard = ({ handleOpenImage, deliveryInfo }) => {
  const classes = useStyle();
  return (
    <Grid className={classes.orderFooterCard}>
      <span className="title">Comprovante</span>
      <span className="contentImg" onClick={handleOpenImage}>
        {deliveryInfo?.image && (
          <img
            src={`data:image/jpeg;base64,${deliveryInfo?.image}`}
            alt="Comprovante"
          />
        )}
      </span>
    </Grid>
  );
};

export const DeliveryButtonsCard = ({ orderData, orderID }) => {
  const classes = useStyle();
  return (
    <Grid className={classes.deliveryButtonsCard}>
      <span>
        {orderData?.resources?.[0]?.content && (
          <>
            <IoMdDownload />
            <a
              href={`data:image/jpeg;base64,${orderData?.resources[0]?.content}`}
              download={`Comprovante pedido ${orderID}`}
              rel="noreferrer"
            >
              Baixar
            </a>
          </>
        )}
      </span>
    </Grid>
  );
};
