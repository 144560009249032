function whatState() {
  if (
    window.location.href.includes("localhost") ||
    window.location.href.includes("stg")
  ) {
    return {
      ABACATE_BACKEND_URL: "https://stg.abacate.frexco.com.br",
      MS_BACKEND_HOST: "https://stg.ms.frexco.com.br",
      MS_API: "https://stg.abacate.frexco.com.br",
    };
  }
  return {
    ABACATE_BACKEND_URL: "https://abacate.frexco.com.br",
    MS_BACKEND_HOST: "https://ms.frexco.com.br",
    MS_API: "https://abacate.frexco.com.br",
  };
}
const { ABACATE_BACKEND_URL, MS_API, MS_BACKEND_HOST } =
  whatState();
export { ABACATE_BACKEND_URL, MS_API, MS_BACKEND_HOST };
