import { CardDashBoard } from "../../../components/Cards/CardDashBoard";
import { ContainerPage } from "../../../components/ContainerPage";
import { H1 } from "../../../components/Heading/Heading";
import {
  Content,
  DashBoardCardContent,
  ExpectedTableContainer,
  MsgNotFoundContent,
  PageTitleContent,
  PaginateContent,
  WithoutSolutionTableContainer,
} from "./styles";
import { useState } from "react";
import {
  DriverBreakTable,
  ExpectedBreakTable,
  WithoutSolutionTable,
} from "./tables";
import OrderServices from "../../../services/orderServices";
import { useEffect } from "react";
import PageLoader from "../../../components/PageLoader";
import { Modal } from "../../../components/Modals/Modal";
import { WalletModalBody } from "../../../components/Modals/WalletModal";
import {
  CouponModalBody,
  DetailRefund,
  NoSolutionModal,
  OtherNegotiationsModalBody,
} from "./modalBody";
import { useMemo } from "react";
import formatCurrency from "../../../utils/formatCurrency";
import { toast } from "react-toastify";
import { AlertModal } from "../../../components/AlertModal";
import { LoadingBackDrop } from "../../../components/LoadingBackDrop";
import { breakTypes, orderTypes } from "../../../types/orderTypes";
import { ConfirmModal } from "../../../components/Modals/ConfirmModal";
import { useRef } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { Paginate } from "../../../components/Paginate/Paginate";
import { useQuery } from "../../../utils/useQuery";
import { useHistory } from "react-router-dom";
import { BreakResolve } from "../../../components/complaintResolver";
import { useAuth } from "../../../hooks/useAuth";
import TicketService from "../../../services/ticketService";
import formatEnchantmentData from "./formatEnchantmentData";
import { CustomLineProgress } from "../../../components/CustomLineProgress";

export const OrderEnchantment = () => {
  const query = useQuery();
  const history = useHistory();
  const { permissions } = useAuth();
  const [loading, setLoading] = useState(true);
  const [loadingCardData, setLoadingCardData] = useState(false);

  const [dataExpectedComplaints, setDataExpectedComplaints] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [complaintDetail, setComplaintDetail] = useState([]);
  const [orderDetail, setOrderDetail] = useState([]);
  const [showBreakResolve, setShowBreakResolve] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [complaintValue, setComplaintValue] = useState(0);
  const [breakInfo, setBreakInfo] = useState(breakTypes);
  const [openCouponModal, setOpenCouponModal] = useState(false);
  const [openWalletModal, setOpenWalletModal] = useState(false);
  const [openOtherNegotiations, setOpenOtherNegotiations] = useState(false);
  const [confirmAction, setConfirmAction] = useState("");
  const [confirmText, setConfirmText] = useState("");
  const [iFoodOptions, setIfoodOptions] = useState({
    value: "",
    trackingCode: "",
  });
  const [selectedTimeFrom, setSelectedTimeFrom] = useState(7);
  const [selectedTimeTo, setSelectedTimeTo] = useState(10);
  const [showCalendar, setShowCalendar] = useState(false);
  const [alertText, setAlertText] = useState("");
  const todayDate = new Date();
  const [selectedDate, setSelectedDate] = useState(() => {
    const date = new Date();
    const itsSunday = (day) => !day;
    const isSundayMondayOrSaturday = (day) => [0, 1, 6].includes(day);
    const allowedTime = isSundayMondayOrSaturday(todayDate.getDay())
      ? new Date().getHours() >= 18
      : new Date().getHours() >= 19;
    const addDays = allowedTime ? 2 : 1;
    date.setDate(date.getDate() + addDays);
    if (itsSunday(date.getDay())) {
      date.setDate(date.getDate() + 1);
    }
    return date;
  });
  const [openWithOutSolution, setOpenWithOutSolution] = useState(false);
  const withOutSolutionObservation = useRef(null);
  const acceptQueryCard = ["pending", "expected", "drivers", "withoutSolution"];
  const queryCard = query.get("card");
  const selectedCard = acceptQueryCard.includes(queryCard)
    ? queryCard
    : acceptQueryCard[1];
  const [dataDriverComplaint, setDataDriverComplaint] = useState([]);
  const [dataWithoutSolutionComplaint, setDataWithoutSolutionComplaint] =
    useState([]);
  const withOutContactObservation = useRef(null);
  const [withoutSolutionPage, setWithoutSolutionPage] = useState(1);
  const [expectedComplaintsPage, setExpectedComplaintsPage] = useState(1);
  const [driverComplaintPage, setDriverComplaintPage] = useState(1);
  const firstRender = useRef(true);

  const getComplaints = async () => {
    try {
      setLoading(true);
      const { data: expectedData } = await OrderServices.getNewComplaints({
        origin: "carambola",
        statusId: 1,
        keyAccount: false,
        page: expectedComplaintsPage,
      });
      const { data: driverData } = await OrderServices.getNewComplaints({
        originReport: 1,
        statusId: 1,
        keyAccount: false,
        page: driverComplaintPage,
      });
      const { data: withoutSolutionData } =
        await OrderServices.getNewComplaints({
          statusId: 1,
          page: withoutSolutionPage,
        });
      setDataExpectedComplaints(expectedData);
      setDataDriverComplaint(driverData);
      setDataWithoutSolutionComplaint(withoutSolutionData);
    } catch {
      toast.error("Erro ao carregar os dados");
    } finally {
      setLoading(false);
      firstRender.current = false;
    }
  };

  const getCardData = () => {
    setLoadingCardData(true);
    const loadData = {
      expected: async () => {
        try {
          const { data: expectedData } = await OrderServices.getNewComplaints({
            origin: "carambola",
            keyAccount: false,
            page: expectedComplaintsPage,
          });
          setDataExpectedComplaints(expectedData);
        } catch (error) {
          toast.error(`Erro ao carregar os dados`);
        } finally {
          setLoadingCardData(false);
        }
      },
      drivers: async () => {
        try {
          const { data: driverData } = await OrderServices.getNewComplaints({
            originReport: 1,
            keyAccount: false,
            page: driverComplaintPage,
          });
          setDataDriverComplaint(driverData);
        } catch (error) {
          toast.error(`Erro ao carregar os dados`);
        } finally {
          setLoadingCardData(false);
        }
      },
      withoutSolution: async () => {
        try {
          const { data: withoutSolutionData } =
            await OrderServices.getNewComplaints({
              statusId: 1,
              page: withoutSolutionPage,
            });
          setDataWithoutSolutionComplaint(withoutSolutionData);
        } catch (error) {
          toast.error(`Erro ao carregar os dados`);
        } finally {
          setLoadingCardData(false);
        }
      },
    }[selectedCard];

    loadData();
  };

  useEffect(() => {
    getComplaints();
  }, []);

  useEffect(() => {
    !firstRender.current && getCardData();
  }, [withoutSolutionPage, expectedComplaintsPage, driverComplaintPage]);

  const handleCloseConfirm = () => {
    setOpenConfirmModal(false);
  };

  const confirmNegotiationText = () => {
    const { negotiation } = breakInfo;
    const text = {
      1: "Tem certeza que deseja gerar um cupom?",
      2: "Tem certeza que deseja gerar um reembolso?",
      3: "Tem certeza que deseja gerar uma reposição?",
      5: "Tem certeza que deseja gerar um desconto no boleto?",
      6: "Tem certeza que deseja finalizar sem contato?",
      7: "Tem certeza que deseja gerar crédito na carteira?",
    };

    setConfirmText(text[negotiation]);
  };

  const handleConfirm = (confirmType) => {
    if (confirmType === "solution") {
      setConfirmText("Tem certeza que deseja finalizar esta tratativa?");
      setConfirmAction(confirmType);
      setOpenConfirmModal(true);
    }

    if (confirmType === "withoutSolution") {
      if (!withOutSolutionObservation.current.value) {
        return toast.error("Escreva um comentário");
      }
      setConfirmText(
        "Tem certeza que deseja finalizar a quebra sem tratativa?"
      );
      setConfirmAction(confirmType);
      return setOpenConfirmModal(true);
    }

    if (confirmType === "negotiation") {
      const { negotiation, refundOption, redeliveryOption } = breakInfo;
      confirmNegotiationText();
      setConfirmAction(confirmType);

      if (!negotiation) {
        return toast.error("Escolha uma tratativa");
      }

      if (negotiation === "2") {
        if (refundOption === "transferBank") {
          if (
            !breakInfo.bankAccount ||
            !breakInfo.bankBranch ||
            !breakInfo.bankDocument ||
            !breakInfo.bankName ||
            !breakInfo.typeAccount
          ) {
            return toast.error("Preencha todos os dados bancários");
          }
        } else if (refundOption === "pix") {
          if (!breakInfo.typePix) {
            return toast.error("Informe o tipo da chave do pix");
          }
          if (!breakInfo.keyPix) {
            return toast.error("Informe a chave do pix");
          }
        } else {
          return toast.error("Escolha o tipo de reembolso");
        }
      }
      if (negotiation === "3") {
        if (
          orderDetail.complaints.some(
            (complaint) => complaint?.action?.name === "Reposição"
          )
        ) {
          return toast.error("Já existe uma reposição para esse pedido");
        }
        if (redeliveryOption === "ifood") {
          if (!iFoodOptions.value || !iFoodOptions.trackingCode) {
            return toast.error("Preencha os dados do iFood");
          }
        } else if (redeliveryOption === "nextDay") {
          if (selectedTimeFrom + 3 > selectedTimeTo) {
            return toast.error(
              "Deve haver um intervalo de 3 horas entre a entrega inicial e a entrega final"
            );
          }
        } else if (redeliveryOption === "") {
          return toast.error("Escolha uma forma de entrega");
        }
      }
      if (negotiation === "6") {
        if (!withOutContactObservation.current.value?.trim()) {
          return toast.error("Informe o motivo!");
        }
      }
      return setOpenConfirmModal(true);
    }
  };

  const handleSubmit = async () => {
    const { complaintId } = formattedComplaintDetail;
    const {
      results: { order, ...complaint },
    } =
      {
        expected: dataExpectedComplaints,
        drivers: dataDriverComplaint,
        withoutSolution: dataWithoutSolutionComplaint,
      }[selectedCard] || {};
    const clientName =
      complaintDetail?.client?.trade_name || complaintDetail?.client?.name;
    const { orderId, clientId } = formattedComplaintDetail;
    setOpenConfirmModal(false);

    if (confirmAction === "withoutSolution") {
      const data = {
        subjectId: 7,
        externalCode: `${complaintId}#complaint`,
        orderId,
        clientId,
        referenceObj: {
          complaint,
          order: {
            client: orderDetail?.client,
            payment: orderDetail?.payment,
            status: orderDetail?.status,
            sub_total_value: orderDetail?.sub_total_value,
            delivery_value: orderDetail?.delivery_value,
            store_name: orderDetail?.store_name,
            catalog_id: orderDetail?.catalog_id,
            id: orderId,
          },
        },
        actionJson: {
          solution: {
            action: 4,
            action_json: {
              without_solution: {
                complaintValue: complaintValue,
                observation: withOutSolutionObservation.current.value,
              },
            },
          },
        },
      };

      try {
        setSubmitLoading(true);
        await TicketService.createTicket(data);
        setAlertText(
          `Tratativa para o pedido #${orderId} foi salva com sucesso`
        );
        showAlert();
      } catch {
        toast.error("Erro ao criar tratativa");
      } finally {
        setSubmitLoading(false);
      }
    }

    if (confirmAction === "negotiation") {
      const { negotiation } = breakInfo;
      let data = {};
      if (negotiation === "1") {
        data = {
          subjectId: 3,
          externalCode: `${complaintDetail?.id}#complaint`,
          orderId,
          clientId,
          referenceObj: {
            complaint,
            order: {
              client: orderDetail?.client,
              payment: orderDetail?.payment,
              status: orderDetail?.status,
              sub_total_value: orderDetail?.sub_total_value,
              delivery_value: orderDetail?.delivery_value,
              store_name: orderDetail?.store_name,
              catalog_id: orderDetail?.catalog_id,
              id: orderId,
            },
          },
          actionJson: {
            solution: {
              action: negotiation,
              action_json: {
                coupon: {
                  key: couponCode,
                  useType: "oneUse",
                  type: "absolute",
                  limitQuantity: 1,
                  discountValue: complaintValue,
                  startValidPeriod: new Date(),
                  finalValidPeriod: new Date(
                    todayDate.setDate(todayDate.getDate() + 30)
                  ),
                  clientName,
                },
              },
            },
          },
        };
      }

      if (negotiation === "2") {
        if (breakInfo.refundOption === "transferBank") {
          data = {
            subjectId: 4,
            externalCode: `${complaintId}#complaint`,
            orderId,
            clientId,
            referenceObj: {
              complaint,
              order: {
                client: orderDetail?.client,
                payment: orderDetail?.payment,
                status: orderDetail?.status,
                sub_total_value: orderDetail?.sub_total_value,
                delivery_value: orderDetail?.delivery_value,
                store_name: orderDetail?.store_name,
                catalog_id: orderDetail?.catalog_id,
                id: orderId,
              },
            },
            actionJson: {
              solution: {
                action: negotiation,
                action_json: {
                  refund: {
                    refundOption: breakInfo?.refundOption,
                    bankAccount: breakInfo?.bankAccount,
                    bankBranch: breakInfo?.bankBranch,
                    bankDocument: breakInfo?.bankDocument,
                    bankName: breakInfo?.bankName,
                    typeAccount: breakInfo?.typeAccount,
                    refundValue: complaintValue,
                  },
                },
              },
            },
          };
        }

        if (breakInfo?.refundOption === "pix") {
          if (!breakInfo?.typePix) {
            return toast.error("Informe o tipo da chave do pix");
          }
          if (!breakInfo?.keyPix) {
            return toast.error("Informe a chave do pix");
          }
          data = {
            subjectId: 4,
            externalCode: `${complaintId}#complaint`,
            orderId,
            clientId,
            referenceObj: {
              complaint,
              order: {
                client: orderDetail?.client,
                payment: orderDetail?.payment,
                status: orderDetail?.status,
                sub_total_value: orderDetail?.sub_total_value,
                delivery_value: orderDetail?.delivery_value,
                store_name: orderDetail?.store_name,
                catalog_id: orderDetail?.catalog_id,
                id: orderId,
              },
            },
            actionJson: {
              solution: {
                action: negotiation,
                action_json: {
                  refund: {
                    refundOption: breakInfo?.refundOption,
                    typePix: breakInfo?.typePix,
                    keyPix: breakInfo?.keyPix,
                    refundValue: complaintValue,
                    favored: breakInfo?.favored,
                  },
                },
              },
            },
          };
        }
      }

      if (negotiation === "3") {
        if (breakInfo.redeliveryOption === "nextDelivery") {
          data = {
            subjectId: 8,
            externalCode: `${complaintId}#complaint`,
            orderId,
            clientId,
            referenceObj: {
              complaint,
              order: {
                client: orderDetail?.client,
                payment: orderDetail?.payment,
                status: orderDetail?.status,
                sub_total_value: orderDetail?.sub_total_value,
                delivery_value: orderDetail?.delivery_value,
                store_name: orderDetail?.store_name,
                catalog_id: orderDetail?.catalog_id,
                id: orderId,
              },
            },
            actionJson: {
              solution: {
                action: negotiation,
                action_json: {
                  reposition: {
                    redeliveryOption: breakInfo?.redeliveryOption,
                    repositionValue: complaintValue,
                  },
                },
              },
            },
          };
        }

        if (breakInfo.redeliveryOption === "nextDay") {
          data = {
            subjectId: 8,
            externalCode: `${complaintId}#complaint`,
            orderId,
            clientId,
            referenceObj: {
              complaint,
              order: {
                client: orderDetail?.client,
                payment: orderDetail?.payment,
                status: orderDetail?.status,
                sub_total_value: orderDetail?.sub_total_value,
                delivery_value: orderDetail?.delivery_value,
                store_name: orderDetail?.store_name,
                catalog_id: orderDetail?.catalog_id,
                id: orderId,
              },
            },
            actionJson: {
              solution: {
                action: negotiation,
                action_json: {
                  reposition: {
                    redeliveryOption: breakInfo?.redeliveryOption,
                    scheduleDate: new Date(selectedDate),
                    scheduleTimeFrom: `${selectedTimeFrom}:00`,
                    scheduleTimeTo: `${selectedTimeTo}:00`,
                    repositionValue: complaintValue,
                  },
                },
              },
            },
          };
        }

        if (breakInfo.redeliveryOption === "ifood") {
          data = {
            subjectId: 8,
            externalCode: `${complaintId}#complaint`,
            orderId,
            clientId,
            referenceObj: {
              complaint,
              order: {
                client: orderDetail?.client,
                payment: orderDetail?.payment,
                status: orderDetail?.status,
                sub_total_value: orderDetail?.sub_total_value,
                delivery_value: orderDetail?.delivery_value,
                store_name: orderDetail?.store_name,
                catalog_id: orderDetail?.catalog_id,
                id: orderId,
              },
            },
            actionJson: {
              solution: {
                action: negotiation,
                action_json: {
                  reposition: {
                    redeliveryOption: breakInfo?.redeliveryOption,
                    iFoodValue: iFoodOptions.value || 0,
                    iFoodTrackingCode: iFoodOptions.trackingCode || "",
                    date: new Date(),
                    repositionValue: complaintValue,
                  },
                },
              },
            },
          };
        }
      }

      if (negotiation === "5") {
        data = {
          subjectId: 5,
          externalCode: `${complaintId}#complaint`,
          orderId,
          clientId,
          referenceObj: {
            complaint,
            order: {
              client: orderDetail?.client,
              payment: orderDetail?.payment,
              status: orderDetail?.status,
              sub_total_value: orderDetail?.sub_total_value,
              delivery_value: orderDetail?.delivery_value,
              store_name: orderDetail?.store_name,
              catalog_id: orderDetail?.catalog_id,
              id: orderId,
            },
          },
          actionJson: {
            solution: {
              action: negotiation,
              action_json: {
                discountOnTicket: {
                  orderId,
                  discountValue: Number(complaintValue.toFixed(2)),
                },
              },
            },
          },
        };
      }

      if (negotiation === "6") {
        data = {
          subjectId: 11,
          externalCode: `${complaintId}#complaint`,
          orderId,
          clientId,
          referenceObj: {
            complaint,
            order: {
              client: orderDetail?.client,
              payment: orderDetail?.payment,
              status: orderDetail?.status,
              sub_total_value: orderDetail?.sub_total_value,
              delivery_value: orderDetail?.delivery_value,
              store_name: orderDetail?.store_name,
              catalog_id: orderDetail?.catalog_id,
              id: orderId,
            },
          },
          actionJson: {
            solution: {
              action: negotiation,
              action_json: {
                withOutContact: {
                  observation: withOutContactObservation.current.value,
                },
              },
            },
          },
        };
      }

      if (negotiation === "7") {
        data = {
          subjectId: 13,
          externalCode: `${complaintDetail?.id}#complaint`,
          orderId,
          clientId,
          referenceObj: {
            complaint,
            order: {
              client: orderDetail?.client,
              payment: orderDetail?.payment,
              status: orderDetail?.status,
              sub_total_value: orderDetail?.sub_total_value,
              delivery_value: orderDetail?.delivery_value,
              store_name: orderDetail?.store_name,
              catalog_id: orderDetail?.catalog_id,
              id: orderId,
            },
          },
          actionJson: {
            solution: {
              action: negotiation,
              action_json: {
                wallet: {
                  creditValue: complaintValue,
                  observation: "",
                },
              },
            },
          },
        };
      }

      try {
        setSubmitLoading(true);

        setAlertText(
          `Tratativa para o pedido #${formattedComplaintDetail.orderId} foi salva com sucesso`
        );

        await TicketService.createTicket(data);

        showAlert();
      } catch (error) {
        const errorMessage = error?.response?.data?.msg;
        toast.error(`Erro ao criar trativa ${errorMessage}`);
      } finally {
        setSubmitLoading(false);
      }
    }
  };

  const formattedComplaintDetail = useMemo(
    () => formatEnchantmentData.formatComplaintDetail(complaintDetail),
    [complaintDetail]
  );

  const formattedExpectedComplaints = useMemo(
    () =>
      formatEnchantmentData.formatExpectedComplaints(dataExpectedComplaints),
    [dataExpectedComplaints]
  );

  const formattedDriverComplaints = useMemo(
    () => formatEnchantmentData.formatDriverComplaints(dataDriverComplaint),
    [dataDriverComplaint]
  );

  const formattedWithoutSolutionComplaints = useMemo(
    () =>
      formatEnchantmentData.formatWithoutSolutionComplaints(
        dataWithoutSolutionComplaint
      ),
    [dataWithoutSolutionComplaint]
  );

  const getOrderDetails = async (orderId) => {
    try {
      const orderData = await OrderServices.GetOrderDetail(orderId);
      setOrderDetail(orderData.data);
    } catch {
      toast.error("Erro ao buscar detalhes do pedido");
    }
  };

  const handleShowDetail = async (complaintId) => {
    let orderId;
    try {
      setSubmitLoading(true);
      const { data } = await OrderServices.getNewComplaintDetail({
        complaintId,
      });
      orderId = data.order.id;
      setComplaintDetail(data);
      handleShowBreakResolve();
    } catch {
      toast.error("Erro ao carregar os dados");
    } finally {
      setSubmitLoading(false);
      getOrderDetails(orderId);
    }
  };

  const handleCloseDetail = () => {
    setOpenModal(false);
  };

  const showAlert = () => {
    setOpenAlert(true);
  };

  const closeAlert = () => {
    const { complaintId } = formattedComplaintDetail;

    setOpenAlert(false);
    handleCloseDetail();
    setOpenCouponModal(false);
    setOpenOtherNegotiations(false);
    setOpenWithOutSolution(false);
    handleHideBreakResolve();

    const updatedComplaints = (complaintToUpdate) =>
      complaintToUpdate?.map((complaint) =>
        complaint.id === complaintId
          ? { ...complaint, status: { id: 5 } }
          : complaint
      );

    const { results: complaints } = {
      expected: dataExpectedComplaints,
      drivers: dataDriverComplaint,
      withoutSolution: dataWithoutSolutionComplaint,
    }[selectedCard];

    const results = updatedComplaints(complaints);
    const newState = (prev) => ({ ...prev, count: prev.count - 1, results });

    const dataSetter = {
      expected: () => {
        setDataExpectedComplaints(newState);
      },
      drivers: () => {
        setDataDriverComplaint(newState);
      },
      withoutSolution: () => {
        setDataWithoutSolutionComplaint(newState);
      },
    }[selectedCard];

    dataSetter();
  };

  const handleCardClick = (card) => {
    history.push(`?card=${card}`);
  };

  const handleShowBreakResolve = () => {
    setShowBreakResolve(true);
  };
  const handleHideBreakResolve = () => {
    setShowBreakResolve(false);
  };

  const generateCouponCode = () => {
    const code = `${formattedComplaintDetail.clientName.slice(
      0,
      4
    )}${new Date().getTime()}`;

    setCouponCode(code.replace(/\s/g, "").toUpperCase());
  };

  const handleCloseNegotiation = () => {
    setOpenCouponModal(false);
    setOpenWalletModal(false);
    setOpenOtherNegotiations(false);
    handleShowBreakResolve(true);
  };

  const handleOpenCreditAnnotation = (value) => {
    setComplaintValue(value);
    setBreakInfo((prev) => ({ ...prev, negotiation: "7" }));
    setOpenWalletModal(true);
    handleHideBreakResolve();
  };

  const handleOpenCoupon = (value) => {
    generateCouponCode();
    setComplaintValue(value);
    setBreakInfo((prev) => ({ ...prev, negotiation: "1" }));
    setOpenCouponModal(true);
    handleHideBreakResolve();
  };

  const handleOpenOtherNegotiations = (value) => {
    setComplaintValue(value);
    setOpenOtherNegotiations(true);
    setIfoodOptions({ value: "", trackingCode: "" });
    setBreakInfo(breakTypes);
    handleHideBreakResolve();
  };

  const handleCloseOtherNegotiations = () => {
    setOpenOtherNegotiations(false);
    handleShowBreakResolve(true);
  };

  const handleOpenWithoutSolution = () => {
    withOutSolutionObservation.current = null;
    setOpenWithOutSolution(true);
    handleHideBreakResolve();
  };

  const handleCloseWithoutSolution = () => {
    handleShowBreakResolve(true);
    setOpenWithOutSolution(false);
  };

  const handleBreakInfoChange = (field, value) => {
    setBreakInfo((prev) => ({ ...prev, [field]: value }));
  };

  const handleIfoodOptionsChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    setIfoodOptions({ ...iFoodOptions, [field]: value });
  };

  const handleShowCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const handleChangeTimeFrom = (e) => {
    setSelectedTimeFrom(e.target.value);
  };

  const handleChangeTimeTo = (e) => {
    setSelectedTimeTo(e.target.value);
  };

  const handleDateChange = (date) => {
    const isSunday = (day) => !day;
    const isSundayMondayOrSaturday = (day) => [0, 1, 6].includes(day);
    const allowedTime = isSundayMondayOrSaturday(todayDate.getDay())
      ? new Date().getHours() >= 18
      : new Date().getHours() >= 19;
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    const todayMoreTwoDays = new Date();
    todayMoreTwoDays.setDate(today.getDate() + 2);

    if (isSunday(date.getDay())) {
      return toast.error("Não realizamos entregas aos domingos!");
    }
    if (allowedTime && tomorrow.getDate() === date.getDate()) {
      return toast.error("Escolha um prazo maior de entrega!");
    }

    const isoDate = new Date(date).toISOString().split("T")[0];

    if (orderDetail.delivery_info) {
      const allowedDays = orderDetail.delivery_info?.allowed_days.map(
        (allowedDay) => new Date(allowedDay).toISOString().split("T")[0]
      );

      if (!allowedDays.includes(isoDate)) {
        return toast.error(
          "Nossa janela de entrega não está aberta para a área desse cliente nesse dia!"
        );
      }
    } else if (isSunday(todayMoreTwoDays.getDay()) || allowedTime) {
      if (isSunday(todayMoreTwoDays.getDay()) && allowedTime) {
        if (date.getDate() > today.getDate() + 4) {
          return toast.error(
            "A loja não permite agendamento com essa distância!"
          );
        }
      } else if (date.getDate() > today.getDate() + 3) {
        return toast.error(
          "A loja não permite agendamento com essa distância!"
        );
      }
    } else {
      if (date.getDate() > today.getDate() + 2) {
        return toast.error(
          "A loja não permite agendamento com essa distância!"
        );
      }
    }

    setSelectedDate(date);
    setShowCalendar(false);
  };

  const handleChangePage = (page) => {
    const anchor = document.querySelector("#back-to-top-anchor");

    !!anchor && anchor.scrollIntoView({ behavior: "smooth" });

    const changePage = {
      expected: () => setExpectedComplaintsPage(page),
      drivers: () => setDriverComplaintPage(page),
      withoutSolution: () => setWithoutSolutionPage(page),
    }[selectedCard];

    changePage();
  };

  const hasPermission = (permission) => {
    return permissions.includes(permission);
  };
  const renderTable = {
    [acceptQueryCard[1]]: formattedExpectedComplaints?.length ? (
      <ExpectedTableContainer>
        <ExpectedBreakTable
          complaints={formattedExpectedComplaints}
          handleShowDetail={handleShowDetail}
        />
        <PaginateContent>
          {dataExpectedComplaints?.pages > 1 && (
            <Paginate
              breakLabel="..."
              nextLabel={<AiOutlineRight />}
              forcePage={expectedComplaintsPage - 1}
              onPageChange={(e) => handleChangePage(e.selected + 1)}
              pageRangeDisplayed={3}
              pageCount={dataExpectedComplaints.pages || 0}
              previousLabel={<AiOutlineLeft />}
              renderOnZeroPageCount={null}
            />
          )}
        </PaginateContent>
      </ExpectedTableContainer>
    ) : (
      <MsgNotFoundContent>
        <h1>Ops...</h1>
        <span>Nada para exibir por aqui</span>
      </MsgNotFoundContent>
    ),
    [acceptQueryCard[2]]: formattedDriverComplaints?.length ? (
      <ExpectedTableContainer>
        <DriverBreakTable
          complaints={formattedDriverComplaints}
          handleShowDetail={handleShowDetail}
        />
        <PaginateContent>
          {dataDriverComplaint?.pages > 1 && (
            <Paginate
              breakLabel="..."
              nextLabel={<AiOutlineRight />}
              forcePage={driverComplaintPage - 1}
              onPageChange={(e) => handleChangePage(e.selected + 1)}
              pageRangeDisplayed={3}
              pageCount={dataDriverComplaint.pages || 0}
              previousLabel={<AiOutlineLeft />}
              renderOnZeroPageCount={null}
            />
          )}
        </PaginateContent>
      </ExpectedTableContainer>
    ) : (
      <MsgNotFoundContent>
        <h1>Ops...</h1>
        <span>Nada para exibir por aqui</span>
      </MsgNotFoundContent>
    ),
    [acceptQueryCard[3]]: formattedWithoutSolutionComplaints?.length ? (
      <WithoutSolutionTableContainer>
        <WithoutSolutionTable
          complaints={formattedWithoutSolutionComplaints}
          handleShowDetail={handleShowDetail}
        />
        <PaginateContent>
          {dataWithoutSolutionComplaint?.pages > 1 && (
            <Paginate
              breakLabel="..."
              nextLabel={<AiOutlineRight />}
              forcePage={withoutSolutionPage - 1}
              onPageChange={(e) => handleChangePage(e.selected + 1)}
              pageRangeDisplayed={3}
              pageCount={dataWithoutSolutionComplaint.pages || 0}
              previousLabel={<AiOutlineLeft />}
              renderOnZeroPageCount={null}
            />
          )}
        </PaginateContent>
      </WithoutSolutionTableContainer>
    ) : (
      <MsgNotFoundContent>
        <h1>Ops...</h1>
        <span>Nada para exibir por aqui</span>
      </MsgNotFoundContent>
    ),
  }[selectedCard];

  if (loading) {
    return <PageLoader />;
  }
  return (
    <ContainerPage>
      <PageTitleContent>
        <H1>Quadro de Avisos - Encantamento</H1>
      </PageTitleContent>
      <DashBoardCardContent>
        <CardDashBoard
          qty={dataExpectedComplaints.count || "0"}
          text="Quebras previstas"
          isSelected={selectedCard === acceptQueryCard[1]}
          onClick={() => handleCardClick(acceptQueryCard[1])}
        />
        <CardDashBoard
          qty={dataDriverComplaint.count || "0"}
          text="Quebras pelo motorista"
          isSelected={selectedCard === acceptQueryCard[2]}
          onClick={() => handleCardClick(acceptQueryCard[2])}
        />
        <CardDashBoard
          qty={dataWithoutSolutionComplaint.count || "0"}
          text="Quebras sem tratativa"
          isSelected={selectedCard === acceptQueryCard[3]}
          onClick={() => handleCardClick(acceptQueryCard[3])}
        />
      </DashBoardCardContent>

      <Content>
        {loadingCardData && <CustomLineProgress />}
        {renderTable}
      </Content>

      <Modal open={openModal} onClose={handleCloseDetail}>
        <DetailRefund
          handleClose={handleCloseDetail}
          complaint={formattedComplaintDetail}
          loading={submitLoading}
          handleConfirm={handleConfirm}
        />
      </Modal>

      <ConfirmModal
        open={openConfirmModal}
        onClose={handleCloseConfirm}
        handleClose={handleCloseConfirm}
        handleAction={handleSubmit}
        confirmText={confirmText}
      />

      <Modal open={showBreakResolve}>
        <BreakResolve
          handleClose={handleHideBreakResolve}
          handleConfirm={handleConfirm}
          storeName={orderDetail?.store_name}
          complaint={formattedComplaintDetail}
          handleOpenCreditAnnotation={handleOpenCreditAnnotation}
          handleOpenCoupon={handleOpenCoupon}
          handleOpenOtherNegotiations={handleOpenOtherNegotiations}
          handleOpenWithoutSolution={handleOpenWithoutSolution}
          editPath="../orders/"
          hasPermission={hasPermission}
        />
      </Modal>

      <Modal open={openCouponModal}>
        <CouponModalBody
          complaint={formattedComplaintDetail}
          couponCode={couponCode}
          complaintValue={formatCurrency(complaintValue)}
          handleClose={handleCloseNegotiation}
          handleConfirm={handleConfirm}
        />
      </Modal>

      <Modal open={openWalletModal}>
        <WalletModalBody
          data={formattedComplaintDetail}
          complaintValue={formatCurrency(complaintValue)}
          handleClose={handleCloseNegotiation}
          handleConfirm={handleConfirm}
        />
      </Modal>

      <Modal open={openOtherNegotiations}>
        <OtherNegotiationsModalBody
          complaint={formattedComplaintDetail}
          breakInfo={breakInfo}
          orderDetail={orderDetail}
          handleClose={handleCloseOtherNegotiations}
          handleBreakInfoChange={handleBreakInfoChange}
          handleIfoodOptionsChange={handleIfoodOptionsChange}
          handleShowCalendar={handleShowCalendar}
          handleChangeTimeFrom={handleChangeTimeFrom}
          handleChangeTimeTo={handleChangeTimeTo}
          handleConfirm={handleConfirm}
          handleDateChange={handleDateChange}
          iFoodOptions={iFoodOptions}
          selectedDate={selectedDate}
          selectedTimeFrom={selectedTimeFrom}
          selectedTimeTo={selectedTimeTo}
          showCalendar={showCalendar}
          withOutContactObservation={withOutContactObservation}
          selectedCard={selectedCard}
        />
      </Modal>

      <NoSolutionModal
        close={handleCloseWithoutSolution}
        open={openWithOutSolution}
        withOutSolutionObservation={withOutSolutionObservation}
        handleConfirm={handleConfirm}
      />

      <AlertModal handleClose={closeAlert} open={openAlert} text={alertText} />
      <LoadingBackDrop open={submitLoading} />
    </ContainerPage>
  );
};
