import { CardDashBoard } from "../../../components/Cards/CardDashBoard";
import { ContainerPage } from "../../../components/ContainerPage";
import { H1 } from "../../../components/Heading/Heading";
import {
  Content,
  DashBoardCardContent,
  DivSearchContent,
  DownloadButton,
  DownloadButtonContent,
  ExpectedTableContainer,
  KeyaccountTableContainer,
  MsgNotFoundContent,
  PageTitleContent,
} from "./styles";
import { useState } from "react";
import {
  DriverBreakTable,
  ExpectedBreakTable,
  KeyaccountBreakTable,
} from "./tables";
import OrderServices from "../../../services/orderServices";
import { useEffect } from "react";
import PageLoader from "../../../components/PageLoader";
import { Modal } from "../../../components/Modals/Modal";
import {
  CouponModalBody,
  DetailRefund,
  NoSolutionModal,
  OtherNegotiationsModalBody,
} from "./modalBody";
import { useMemo } from "react";
import formatCurrency from "../../../utils/formatCurrency";
import { toast } from "react-toastify";
import { AlertModal } from "../../../components/AlertModal";
import { LoadingBackDrop } from "../../../components/LoadingBackDrop";
import { formatPhone } from "../../../utils/formatPhone";
import { breakTypes } from "../../../types/orderTypes";
import { ConfirmModal } from "../../../components/Modals/ConfirmModal";
import { useRef } from "react";
import { useQuery } from "../../../utils/useQuery";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { SelectDateSearch } from "./selectDateSearch";
import { formattedData, getComplaintValue } from "./formattData";
import { BreakResolve } from "../../../components/complaintResolver";
import TicketService from "../../../services/ticketService";
export const Farmers = () => {
  const query = useQuery();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const { permissions } = useAuth();

  const [dataExpectedComplaints, setDataExpectedComplaints] = useState([]);

  const [openModal, setOpenModal] = useState(false);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [complaintDetail, setComplaintDetail] = useState([]);
  const [orderDetail, setOrderDetail] = useState([]);
  const [showBreakResolve, setShowBreakResolve] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [complaintValue, setComplaintValue] = useState(0);
  const [breakInfo, setBreakInfo] = useState(breakTypes);
  const [openCouponModal, setOpenCouponModal] = useState(false);
  const [openOtherNegotiations, setOpenOtherNegotiations] = useState(false);
  const [confirmAction, setConfirmAction] = useState("");
  const [confirmText, setConfirmText] = useState("");
  const [iFoodOptions, setIfoodOptions] = useState({
    value: "",
    trackingCode: "",
  });
  const [selectedTimeFrom, setSelectedTimeFrom] = useState(7);
  const [selectedTimeTo, setSelectedTimeTo] = useState(10);
  const [showCalendar, setShowCalendar] = useState(false);
  const [alertText, setAlertText] = useState("");
  const todayDate = new Date();
  const [selectedDate, setSelectedDate] = useState(() => {
    const date = new Date();
    const itsSunday = (day) => !day;
    const isSundayMondayOrSaturday = (day) => [0, 1, 6].includes(day);
    const allowedTime = isSundayMondayOrSaturday(todayDate.getDay())
      ? new Date().getHours() >= 18
      : new Date().getHours() >= 19;
    const addDays = allowedTime ? 2 : 1;
    date.setDate(date.getDate() + addDays);
    if (itsSunday(date.getDay())) {
      date.setDate(date.getDate() + 1);
    }
    return date;
  });
  const [openWithOutSolution, setOpenWithOutSolution] = useState(false);
  const withOutSolutionObservation = useRef(null);
  const withOutContactObservation = useRef(null);
  const acceptQueryCard = ["expected", "drivers", "keyaccount"];
  const queryCard = query.get("card");
  const selectedCard = acceptQueryCard.includes(queryCard)
    ? queryCard
    : acceptQueryCard[0];
  const [dataDriverComplaint, setDataDriverComplaint] = useState([]);
  const [dataKeyaccountComplaint, setDataKeyaccountComplaint] = useState([]);
  const [selectedSearchDateFrom, setSelectedSearchDateFrom] = useState(() => {
    const todayDate = new Date();
    todayDate.setDate(todayDate.getDate() - 1);
    return todayDate;
  });
  const [selectedSearchDateTo, setSelectedSearchDateTo] = useState(new Date());
  const handleChangeSearchDateFrom = (date) => {
    const dateFrom = new Date(date).setHours(0, 1, 0, 0);
    const dateTo = new Date(selectedSearchDateTo).setHours(0, 1, 0, 0);
    if (dateFrom > dateTo) {
      return toast.error("A data inicial não pode ser maior que a data final");
    }
    setSelectedSearchDateFrom(dateFrom);
  };
  const handleChangeSearchDateTo = (date) => {
    const dateFrom = new Date(selectedSearchDateFrom).setHours(23, 59, 0, 0);
    const dateTo = new Date(date).setHours(23, 59, 0, 0);
    if (dateTo < dateFrom) {
      return toast.error("A data final não pode ser menor que a data inicial");
    }
    setSelectedSearchDateTo(dateTo);
  };
  const [sort, setSort] = useState("desc");

  const getComplaints = async () => {
    try {
      setLoading(true);
      const { data: expectedData } = await OrderServices.getComplaints({
        originReport: 4,
        statusId: 6,
        dateFrom: new Date(selectedSearchDateFrom),
        dateTo: new Date(selectedSearchDateTo),
        sort,
      });
      const { data: driverData } = await OrderServices.getComplaints({
        originReport: 1,
        statusId: 6,
        dateFrom: new Date(selectedSearchDateFrom),
        dateTo: new Date(selectedSearchDateTo),
        sort,
      });
      const { data: keyaccountData } = await OrderServices.getComplaints({
        statusId: 6,
        dateFrom: new Date(selectedSearchDateFrom),
        dateTo: new Date(selectedSearchDateTo),
        sort,
      });
      setDataExpectedComplaints(expectedData || []);
      setDataDriverComplaint(driverData || []);
      setDataKeyaccountComplaint(keyaccountData || []);
    } catch {
      toast.error("Erro ao carregar os dados");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getComplaints();
  }, [selectedSearchDateFrom, selectedSearchDateTo, sort]);

  const handleCloseConfirm = () => {
    setOpenConfirmModal(false);
  };

  const confirmNegotiationText = () => {
    const { negotiation } = breakInfo;
    if (negotiation === "1") {
      setConfirmText("Tem certeza que deseja gerar um cupom?");
    }
    if (negotiation === "2") {
      setConfirmText("Tem certeza que deseja gerar um reembolso?");
    }
    if (negotiation === "3") {
      setConfirmText("Tem certeza que deseja gerar uma reposição?");
    }
    if (negotiation === "5") {
      setConfirmText("Tem certeza que deseja gerar um desconto no boleto?");
    }
    if (negotiation === "6") {
      setConfirmText("Tem certeza que deseja finalizar sem contato?");
    }
    if (negotiation === "7") {
      setConfirmText("Tem certeza que deseja gerar crédito na carteira?");
    }
  };

  const handleConfirm = (confirmType) => {
    if (confirmType === "solution") {
      setConfirmText("Tem certeza que deseja finalizar esta tratativa?");
      setConfirmAction(confirmType);
      setOpenConfirmModal(true);
    }

    if (confirmType === "withoutSolution") {
      if (!withOutSolutionObservation.current.value) {
        return toast.error("Escreva um comentário");
      }
      setConfirmText(
        "Tem certeza que deseja finalizar a quebra sem tratativa?"
      );
      setConfirmAction(confirmType);
      return setOpenConfirmModal(true);
    }

    if (confirmType === "negotiation") {
      const { negotiation, refundOption, redeliveryOption } = breakInfo;
      confirmNegotiationText();
      setConfirmAction(confirmType);

      if (!negotiation) {
        return toast.error("Escolha uma tratativa");
      }

      if (negotiation === "2") {
        if (refundOption === "transferBank") {
          if (
            !breakInfo.bankAccount ||
            !breakInfo.bankBranch ||
            !breakInfo.bankDocument ||
            !breakInfo.bankName ||
            !breakInfo.typeAccount
          ) {
            return toast.error("Preencha todos os dados bancários");
          }
        } else if (refundOption === "pix") {
          if (!breakInfo.typePix) {
            return toast.error("Informe o tipo da chave do pix");
          }
          if (!breakInfo.keyPix) {
            return toast.error("Informe a chave do pix");
          }
        } else {
          return toast.error("Escolha o tipo de reembolso");
        }
      }

      if (negotiation === "3") {
        if (
          orderDetail.complaints.some(
            (complaint) => complaint?.action?.name === "Reposição"
          )
        ) {
          return toast.error("Já existe uma reposição para esse pedido");
        }
        if (redeliveryOption === "ifood") {
          if (!iFoodOptions.value || !iFoodOptions.trackingCode) {
            return toast.error("Preencha os dados do iFood");
          }
        } else if (redeliveryOption === "nextDay") {
          if (selectedTimeFrom + 3 > selectedTimeTo) {
            return toast.error(
              "Deve haver um intervalo de 3 horas entre a entrega inicial e a entrega final"
            );
          }
        } else if (redeliveryOption === "") {
          return toast.error("Escolha uma forma de entrega");
        }
      }
      if (negotiation === "6") {
        if (!withOutContactObservation.current.value?.trim()) {
          return toast.error("Informe o motivo!");
        }
      }
      return setOpenConfirmModal(true);
    }
  };

  const handleSubmit = async () => {
    const { complaintId } = formattedComplaintDetail;
    const complaint =
      selectedCard === "expected"
        ? dataExpectedComplaints
        : dataDriverComplaint;
    const clientName =
      complaintDetail?.client?.trade_name || complaintDetail?.client?.name;
    const orderId = complaintDetail?.order_id;
    const clientId = complaintDetail?.client?.id;
    setOpenConfirmModal(false);
    if (confirmAction === "solution") {
      const data = {
        step: "enchantment",
      };
      try {
        setSubmitLoading(true);
        await OrderServices.updateSolution(complaintId, data);
        setAlertText("Tratativa foi salva com sucesso!");
        showAlert();
      } catch {
        toast.error("Erro ao salvar tratativa");
      } finally {
        setSubmitLoading(false);
      }
    }

    if (confirmAction === "withoutSolution") {
      const data = {
        subjectId: 7,
        externalCode: `${complaintId}#complaint`,
        orderId,
        clientId,
        referenceObj: {
          complaint,
        },
        actionJson: {
          solution: {
            action: 4,
            action_json: {
              without_solution: {
                complaintValue: complaintValue,
                observation: withOutSolutionObservation.current.value,
              },
            },
          },
        },
      };

      try {
        setSubmitLoading(true);
        await TicketService.createTicket(data);
        setAlertText(
          `Tratativa para o pedido #${orderId} foi salva com sucesso`
        );
        showAlert();
      } catch {
        toast.error("Erro ao criar tratativa");
      } finally {
        setSubmitLoading(false);
      }
    }

    if (confirmAction === "negotiation") {
      const { negotiation } = breakInfo;

      let data = {};
      if (negotiation === "1") {
        data = {
          subjectId: 3,
          externalCode: `${complaintDetail?.id}#complaint`,
          orderId,
          clientId,
          referenceObj: {
            complaint,
          },
          actionJson: {
            solution: {
              action: negotiation,
              action_json: {
                coupon: {
                  key: couponCode,
                  useType: "oneUse",
                  type: "absolute",
                  limitQuantity: 1,
                  discountValue: complaintValue,
                  startValidPeriod: new Date(),
                  finalValidPeriod: new Date(
                    todayDate.setDate(todayDate.getDate() + 30)
                  ),
                  clientName,
                },
              },
            },
          },
        };
      }

      if (negotiation === "2") {
        if (breakInfo.refundOption === "transferBank") {
          data = {
            subjectId: 4,
            externalCode: `${complaintId}#complaint`,
            orderId,
            clientId,
            referenceObj: {
              complaint,
            },
            actionJson: {
              solution: {
                action: negotiation,
                action_json: {
                  refund: {
                    refundOption: breakInfo?.refundOption,
                    bankAccount: breakInfo?.bankAccount,
                    bankBranch: breakInfo?.bankBranch,
                    bankDocument: breakInfo?.bankDocument,
                    bankName: breakInfo?.bankName,
                    typeAccount: breakInfo?.typeAccount,
                    refundValue: complaintValue,
                  },
                },
              },
            },
          };
        }

        if (breakInfo?.refundOption === "pix") {
          if (!breakInfo?.typePix) {
            return toast.error("Informe o tipo da chave do pix");
          }
          if (!breakInfo?.keyPix) {
            return toast.error("Informe a chave do pix");
          }
          data = {
            subjectId: 4,
            externalCode: `${complaintId}#complaint`,
            orderId,
            clientId,
            referenceObj: {
              complaint,
            },
            actionJson: {
              solution: {
                action: negotiation,
                action_json: {
                  refund: {
                    refundOption: breakInfo?.refundOption,
                    typePix: breakInfo?.typePix,
                    keyPix: breakInfo?.keyPix,
                    refundValue: complaintValue,
                    favored: breakInfo?.favored,
                  },
                },
              },
            },
          };
        }
      }

      if (negotiation === "3") {
        if (breakInfo.redeliveryOption === "nextDelivery") {
          data = {
            subjectId: 8,
            externalCode: `${complaintId}#complaint`,
            orderId,
            clientId,
            referenceObj: {
              complaint,
              order: {
                client: orderDetail?.client,
                payment: orderDetail?.payment,
                status: orderDetail?.status,
                sub_total_value: orderDetail?.sub_total_value,
                delivery_value: orderDetail?.delivery_value,
                store_name: orderDetail?.store_name,
                catalog_id: orderDetail?.catalog_id,
              },
            },
            actionJson: {
              solution: {
                action: negotiation,
                action_json: {
                  reposition: {
                    redeliveryOption: breakInfo?.redeliveryOption,
                    repositionValue: complaintValue,
                  },
                },
              },
            },
          };
        }

        if (breakInfo.redeliveryOption === "nextDay") {
          data = {
            subjectId: 8,
            externalCode: `${complaintId}#complaint`,
            orderId,
            clientId,
            referenceObj: {
              complaint,
              order: orderDetail,
            },
            actionJson: {
              solution: {
                action: negotiation,
                action_json: {
                  reposition: {
                    redeliveryOption: breakInfo?.redeliveryOption,
                    scheduleDate: new Date(selectedDate),
                    scheduleTimeFrom: `${selectedTimeFrom}:00`,
                    scheduleTimeTo: `${selectedTimeTo}:00`,
                    repositionValue: complaintValue,
                  },
                },
              },
            },
          };
        }

        if (breakInfo.redeliveryOption === "ifood") {
          data = {
            subjectId: 8,
            externalCode: `${complaintId}#complaint`,
            orderId,
            clientId,
            referenceObj: {
              complaint,
            },
            actionJson: {
              solution: {
                action: negotiation,
                action_json: {
                  reposition: {
                    redeliveryOption: breakInfo?.redeliveryOption,
                    iFoodValue: iFoodOptions.value || 0,
                    iFoodTrackingCode: iFoodOptions.trackingCode || "",
                    date: new Date(),
                    repositionValue: complaintValue,
                  },
                },
              },
            },
          };
        }
      }

      if (negotiation === "5") {
        data = {
          subjectId: 5,
          externalCode: `${complaintId}#complaint`,
          orderId,
          clientId,
          referenceObj: {
            complaint,
          },
          actionJson: {
            solution: {
              action: negotiation,
              action_json: {
                discountOnTicket: {
                  orderId,
                  discountValue: Number(complaintValue.toFixed(2)),
                },
              },
            },
          },
        };
      }
      if (negotiation === "6") {
        data = {
          subjectId: 11,
          externalCode: `${complaintId}#complaint`,
          orderId,
          clientId,
          referenceObj: {
            complaint,
          },
          actionJson: {
            solution: {
              action: negotiation,
              action_json: {
                withOutContact: {
                  observation: withOutContactObservation.current.value,
                },
              },
            },
          },
        };
      }
      if (negotiation === "7") {
        data = {
          subjectId: 13,
          externalCode: `${complaintDetail?.id}#complaint`,
          orderId,
          clientId,
          referenceObj: {
            complaint,
          },
          actionJson: {
            solution: {
              action: negotiation,
              action_json: {
                wallet: {
                  creditValue: complaintValue,
                  observation: "",
                },
              },
            },
          },
        };
      }

      try {
        setSubmitLoading(true);

        setAlertText(
          `Tratativa para o pedido #${formattedComplaintDetail.orderId} foi salva com sucesso`
        );

        await TicketService.createTicket(data);

        showAlert();
      } catch (error) {
        const errorMessage = error?.response?.data?.msg;
        toast.error(`Erro ao criar trativa ${errorMessage}`);
      } finally {
        setSubmitLoading(false);
      }
    }
  };

  const formattedComplaintDetail = useMemo(() => {
    if (complaintDetail) {
      return {
        ...complaintDetail,
        orderId: complaintDetail?.order_id,
        complaintId: complaintDetail?.id,
        clientName:
          complaintDetail?.client?.trade_name || complaintDetail?.client?.name,
        clientPhone:
          formatPhone(complaintDetail?.client?.phone_number_1) ||
          formatPhone(complaintDetail?.client?.phone_number_2),
        clientEmail: complaintDetail?.client?.email,
        createdBy: complaintDetail?.responsible?.created_by,
        complaintValue: formatCurrency(
          getComplaintValue(complaintDetail?.items)
        ),
      };
    }
  }, [complaintDetail]);

  const formattedExpectedComplaints = useMemo(
    () =>
      dataExpectedComplaints
        .filter((complaint) => complaint.status.id === 6)
        .map(formattedData),
    [dataExpectedComplaints]
  );

  const formattedDriverComplaints = useMemo(
    () =>
      dataDriverComplaint
        .filter(
          (complaint) =>
            complaint.status.id === 6 && !complaint.client.key_account
        )
        .map(formattedData),
    [dataDriverComplaint]
  );

  const formattedKeyaccountComplaints = useMemo(
    () =>
      dataKeyaccountComplaint
        .filter(
          (complaint) =>
            complaint.status.id === 6 && !!complaint.client.key_account
        )
        .map(formattedData),
    [dataKeyaccountComplaint]
  );

  const getOrderDetails = async (orderId) => {
    try {
      const orderData = await OrderServices.GetOrderDetail(orderId);
      setOrderDetail(orderData.data);
    } catch {
      toast.error("Erro ao buscar detalhes do pedido");
    }
  };

  const handleShowDetail = async (complaintId) => {
    let orderId;
    try {
      setSubmitLoading(true);
      const { data } = await OrderServices.getComplaintsDetail(complaintId);
      orderId = data.order_id;
      setComplaintDetail(data);
      handleShowBreakResolve();
    } catch {
      toast.error("Erro ao carregar os dados");
    } finally {
      setSubmitLoading(false);
      getOrderDetails(orderId);
    }
  };

  const handleCloseDetail = () => {
    setOpenModal(false);
  };

  const showAlert = () => {
    setOpenAlert(true);
  };

  const closeAlert = () => {
    const { complaintId } = formattedComplaintDetail;
    setOpenAlert(false);
    handleCloseDetail();
    setOpenCouponModal(false);
    setOpenOtherNegotiations(false);
    setOpenWithOutSolution(false);
    handleHideBreakResolve();

    const updatedComplaints = (complaintToUpdate) =>
      complaintToUpdate.map((complaint) =>
        complaint.id === complaintId
          ? { ...complaint, status: { id: 5 } }
          : complaint
      );

    const dataSetter = {
      expected: () =>
        setDataExpectedComplaints(updatedComplaints(dataExpectedComplaints)),
      drivers: () =>
        setDataDriverComplaint(updatedComplaints(dataDriverComplaint)),
      keyaccount: () =>
        setDataKeyaccountComplaint(updatedComplaints(dataKeyaccountComplaint)),
    }[selectedCard];
    dataSetter();
  };
  const handleCardClick = (card) => {
    history.push(`?card=${card}`);
  };

  const handleShowBreakResolve = () => {
    setShowBreakResolve(true);
  };
  const handleHideBreakResolve = () => {
    setShowBreakResolve(false);
  };

  const generateCouponCode = () => {
    const code = `${formattedComplaintDetail.clientName.slice(
      0,
      4
    )}${new Date().getTime()}`;

    setCouponCode(code.replace(/\s/g, "").toUpperCase());
  };

  const handleCloseNegotiation = () => {
    setOpenCouponModal(false);
    setOpenOtherNegotiations(false);
    handleShowBreakResolve(true);
  };

  const handleOpenCoupon = (value) => {
    generateCouponCode();
    setComplaintValue(value);
    setBreakInfo((prev) => ({ ...prev, negotiation: "1" }));
    setOpenCouponModal(true);
    handleHideBreakResolve();
  };

  const handleOpenOtherNegotiations = (value) => {
    setComplaintValue(value);
    setOpenOtherNegotiations(true);
    setIfoodOptions({ value: "", trackingCode: "" });
    setBreakInfo(breakTypes);
    handleHideBreakResolve();
  };

  const handleCloseOtherNegotiations = () => {
    setOpenOtherNegotiations(false);
    handleShowBreakResolve(true);
  };

  const handleOpenWithoutSolution = () => {
    withOutSolutionObservation.current = null;
    setOpenWithOutSolution(true);
    handleHideBreakResolve();
  };

  const handleCloseWithoutSolution = () => {
    handleShowBreakResolve(true);
    setOpenWithOutSolution(false);
  };

  const handleBreakInfoChange = (field, value) => {
    setBreakInfo((prev) => ({ ...prev, [field]: value }));
  };

  const handleIfoodOptionsChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    setIfoodOptions({ ...iFoodOptions, [field]: value });
  };

  const handleShowCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const handleChangeTimeFrom = (e) => {
    setSelectedTimeFrom(e.target.value);
  };

  const handleChangeTimeTo = (e) => {
    setSelectedTimeTo(e.target.value);
  };

  const handleDateChange = (date) => {
    const isSunday = (day) => !day;
    const isSundayMondayOrSaturday = (day) => [0, 1, 6].includes(day);
    const allowedTime = isSundayMondayOrSaturday(todayDate.getDay())
      ? new Date().getHours() >= 18
      : new Date().getHours() >= 19;
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    const todayMoreTwoDays = new Date();
    todayMoreTwoDays.setDate(today.getDate() + 2);

    if (isSunday(date.getDay())) {
      return toast.error("Não realizamos entregas aos domingos!");
    }
    if (allowedTime && tomorrow.getDate() === date.getDate()) {
      return toast.error("Escolha um prazo maior de entrega!");
    }

    const isoDate = new Date(date).toISOString().split("T")[0];

    if (orderDetail.delivery_info) {
      const allowedDays = orderDetail.delivery_info?.allowed_days.map(
        (allowedDay) => new Date(allowedDay).toISOString().split("T")[0]
      );

      if (!allowedDays.includes(isoDate)) {
        return toast.error(
          "Nossa janela de entrega não está aberta para a área desse cliente nesse dia!"
        );
      }
    } else if (isSunday(todayMoreTwoDays.getDay()) || allowedTime) {
      if (isSunday(todayMoreTwoDays.getDay()) && allowedTime) {
        if (date.getDate() > today.getDate() + 4) {
          return toast.error(
            "A loja não permite agendamento com essa distância!"
          );
        }
      } else if (date.getDate() > today.getDate() + 3) {
        return toast.error(
          "A loja não permite agendamento com essa distância!"
        );
      }
    } else {
      if (date.getDate() > today.getDate() + 2) {
        return toast.error(
          "A loja não permite agendamento com essa distância!"
        );
      }
    }

    setSelectedDate(date);
    setShowCalendar(false);
  };

  const hasPermission = (permission) => {
    return permissions.includes(permission);
  };

  const handleSort = () => {
    setSort((prev) => (prev === "desc" ? "asc" : "desc"));
  };

  const downloadXlsx = async () => {
    const dateFrom = new Date(selectedSearchDateFrom).toLocaleDateString();
    const dateTo = new Date(selectedSearchDateTo).toLocaleDateString();
    const cardIsValid = selectedCard === acceptQueryCard[0];
    const originReport = cardIsValid ? 4 : 1;
    const fileName = cardIsValid
      ? `Farmers quebra prevista ${dateFrom} - ${dateTo}.xlsx`
      : `Farmers quebras pelo motorista ${dateFrom} - ${dateTo}.xlsx`;
    await OrderServices.getFarmersXlsx({
      dateFrom: new Date(selectedSearchDateFrom),
      dateTo: new Date(selectedSearchDateTo),
      statusId: 6,
      exportType: "xlsx",
      originReport,
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        toast.error(`Erro ao fazer download ${error?.message}`);
      });
  };

  const renderTable = {
    [acceptQueryCard[0]]: formattedExpectedComplaints?.length ? (
      <ExpectedTableContainer>
        <ExpectedBreakTable
          complaints={formattedExpectedComplaints}
          handleShowDetail={handleShowDetail}
          handleSort={handleSort}
          sort={sort}
        />
      </ExpectedTableContainer>
    ) : (
      <MsgNotFoundContent>
        <h1>Ops...</h1>
        <span>Nada para exibir por aqui</span>
      </MsgNotFoundContent>
    ),
    [acceptQueryCard[1]]: formattedDriverComplaints?.length ? (
      <ExpectedTableContainer>
        <DriverBreakTable
          complaints={formattedDriverComplaints}
          handleShowDetail={handleShowDetail}
          handleSort={handleSort}
          sort={sort}
        />
      </ExpectedTableContainer>
    ) : (
      <MsgNotFoundContent>
        <h1>Ops...</h1>
        <span>Nada para exibir por aqui</span>
      </MsgNotFoundContent>
    ),
    [acceptQueryCard[2]]: formattedKeyaccountComplaints?.length ? (
      <KeyaccountTableContainer>
        <KeyaccountBreakTable
          complaints={formattedKeyaccountComplaints}
          handleShowDetail={handleShowDetail}
          handleSort={handleSort}
          sort={sort}
        />
      </KeyaccountTableContainer>
    ) : (
      <MsgNotFoundContent>
        <h1>Ops...</h1>
        <span>Nada para exibir por aqui</span>
      </MsgNotFoundContent>
    ),
  }[selectedCard];

  if (loading) {
    return <PageLoader />;
  }
  return (
    <ContainerPage id="back-to-top-anchor">
      <PageTitleContent>
        <H1>Quadro de Avisos - Farmers</H1>
      </PageTitleContent>
      <DivSearchContent>
        <DashBoardCardContent>
          <CardDashBoard
            qty={formattedExpectedComplaints?.length || "0"}
            text="Quebras previstas"
            isSelected={selectedCard === acceptQueryCard[0]}
            onClick={() => handleCardClick(acceptQueryCard[0])}
          />
          <CardDashBoard
            qty={formattedDriverComplaints?.length || "0"}
            text="Quebras pelo motorista"
            isSelected={selectedCard === acceptQueryCard[1]}
            onClick={() => handleCardClick(acceptQueryCard[1])}
          />
          <CardDashBoard
            qty={formattedKeyaccountComplaints?.length || "0"}
            text="Quebras de clientes KA"
            isSelected={selectedCard === acceptQueryCard[2]}
            onClick={() => handleCardClick(acceptQueryCard[2])}
          />
        </DashBoardCardContent>
        <SelectDateSearch
          selectedSearchDateFrom={selectedSearchDateFrom}
          selectedSearchDateTo={selectedSearchDateTo}
          handleChangeSearchDateFrom={handleChangeSearchDateFrom}
          handleChangeSearchDateTo={handleChangeSearchDateTo}
        />
        <DownloadButtonContent>
          <DownloadButton onClick={downloadXlsx}>Download</DownloadButton>
        </DownloadButtonContent>
      </DivSearchContent>

      <Content>{renderTable}</Content>

      <Modal open={openModal} onClose={handleCloseDetail}>
        <DetailRefund
          handleClose={handleCloseDetail}
          complaint={formattedComplaintDetail}
          loading={submitLoading}
          handleConfirm={handleConfirm}
        />
      </Modal>

      <ConfirmModal
        open={openConfirmModal}
        onClose={handleCloseConfirm}
        handleClose={handleCloseConfirm}
        handleAction={handleSubmit}
        confirmText={confirmText}
      />

      <Modal open={showBreakResolve}>
        <BreakResolve
          handleClose={handleHideBreakResolve}
          storeName={orderDetail?.store_name}
          handleConfirm={handleConfirm}
          complaint={formattedComplaintDetail}
          handleOpenCoupon={handleOpenCoupon}
          handleOpenOtherNegotiations={handleOpenOtherNegotiations}
          handleOpenWithoutSolution={handleOpenWithoutSolution}
          hasPermission={hasPermission}
          editPath="../orders/"
        />
      </Modal>

      <Modal open={openCouponModal}>
        <CouponModalBody
          complaint={formattedComplaintDetail}
          couponCode={couponCode}
          complaintValue={formatCurrency(complaintValue)}
          handleClose={handleCloseNegotiation}
          handleConfirm={handleConfirm}
        />
      </Modal>

      <Modal open={openOtherNegotiations}>
        <OtherNegotiationsModalBody
          complaint={formattedComplaintDetail}
          orderDetail={orderDetail}
          breakInfo={breakInfo}
          handleClose={handleCloseOtherNegotiations}
          handleBreakInfoChange={handleBreakInfoChange}
          handleIfoodOptionsChange={handleIfoodOptionsChange}
          handleShowCalendar={handleShowCalendar}
          handleChangeTimeFrom={handleChangeTimeFrom}
          handleChangeTimeTo={handleChangeTimeTo}
          handleConfirm={handleConfirm}
          handleDateChange={handleDateChange}
          iFoodOptions={iFoodOptions}
          selectedDate={selectedDate}
          selectedTimeFrom={selectedTimeFrom}
          selectedTimeTo={selectedTimeTo}
          showCalendar={showCalendar}
          hasPermission={hasPermission}
          withOutContactObservation={withOutContactObservation}
        />
      </Modal>

      <NoSolutionModal
        close={handleCloseWithoutSolution}
        open={openWithOutSolution}
        withOutSolutionObservation={withOutSolutionObservation}
        handleConfirm={handleConfirm}
      />

      <AlertModal handleClose={closeAlert} open={openAlert} text={alertText} />
      <LoadingBackDrop open={submitLoading} />
    </ContainerPage>
  );
};
